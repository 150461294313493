import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: function () {
      return import("../views/HomeView.vue");
    },
  },
  {
    path: "/post/:id",
    name: "post",
    component: function () {
      return import("../views/Post.vue");
    },
  },
  {
    path: "/page/:slug",
    name: "page",
    component: function () {
      return import("../views/Page.vue");
    },
  },
  {
    path: "/it",
    name: "IT",
    component: function () {
      return import("../views/IT.vue");
    },
  },
  {
    path: "/404",
    name: "not-found",
    component: function () {
      return import("../views/404.vue");
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
