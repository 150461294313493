<template>

  <v-app :class="$vuetify.display.mobile ? 'pa-5' : 'pa-10'" theme="ducTheme">
    <v-navigation-drawer v-model="drawer" class="px-10 rounded-e-xl" :border="0" :width="400" app>

      <v-icon class="mt-10" v-if="$vuetify.display.mobile" @click="drawer = !drawer" icon="mdi-close"></v-icon>
      <v-img :class="$vuetify.display.mobile ? `` : `mt-10`" :src="require('@/assets/logoWeb.png')"
        :height="220"></v-img>

      <center>
        <v-dialog max-width="500">
          <template v-slot:activator="{ props: activatorProps }">
            <v-btn v-bind="activatorProps" class="mt-10" size="small" color="primary" variant="outlined" rounded>
              <span>English</span>
              &nbsp;
              &nbsp;
              <v-icon icon="mdi-translate"></v-icon>
            </v-btn>
          </template>
          <template v-slot:default="{ isActive }">
            <v-card title="الترجمة غير متوفرة حالياً">
              <v-card-text>
                حاول لاحقاً
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text="Close" @click="isActive.value = false"></v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </center>
      <v-list class="mt-10">
        <v-menu v-for="nav in menu.items" :key="nav.title">
          <template v-slot:activator="{ props }">
            <v-list-item @click="menuClick(nav.submenu, nav.link)" v-bind="props" rounded="xl" :title="nav.title">
              <template v-slot:prepend>
                <v-icon :size="15" color="info" icon="mdi-circle"></v-icon>
              </template>
              <template v-if="nav.submenu != null" v-slot:append>
                <v-icon icon="mdi-menu-down"></v-icon>
              </template>
            </v-list-item>
          </template>
          <v-list rounded="xl" color="#FFFFFF">
            <v-list-item :href="checkMenuLink(item.link)" v-for="(item, index) in nav.submenu" :key="index">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list>
      <div v-if="menu.length == 0">
        <v-skeleton-loader type="text"></v-skeleton-loader>
        <v-skeleton-loader type="text"></v-skeleton-loader>
        <v-skeleton-loader type="text"></v-skeleton-loader>
        <v-skeleton-loader type="text"></v-skeleton-loader>
        <v-skeleton-loader type="text"></v-skeleton-loader>
        <v-skeleton-loader type="text"></v-skeleton-loader>
        <v-skeleton-loader type="text"></v-skeleton-loader>
        <v-skeleton-loader type="text"></v-skeleton-loader>
      </div>
      <template v-slot:append>
        <div style="text-align: center;" id="socialButtons">
          <v-btn href="https://www.facebook.com/dijlamedia" target="_blank" color="#0042C5" variant="text" icon>
            <v-icon icon="mdi-facebook"></v-icon>
          </v-btn>
          <v-btn color="#01AFDF" variant="text" icon>
            <v-icon icon="mdi-twitter"></v-icon>
          </v-btn>
          <v-btn href="https://www.youtube.com/channel/UCNNBIlOtI1bQsHO-C7rUv-g" target="_blank" color="#B70000"
            variant="text" icon>
            <v-icon icon="mdi-youtube"></v-icon>
          </v-btn>
          <v-btn href="https://www.instagram.com/duc.edu.iq/" target="_blank" color="#F69000" variant="text" icon>
            <v-icon icon="mdi-instagram"></v-icon>
          </v-btn>
        </div>
        <div class="pa-2 mb-10">
          <center>
            <small>
              <b>
                Dijlah University College<br />Copyright &copy; {{ new Date().getFullYear() }}
              </b>
            </small>
          </center>
        </div>
      </template>
    </v-navigation-drawer>
    <v-app-bar v-if="$vuetify.display.mobile" class="rounded-b-xl" :elevation="0" :height="120">
      <template v-slot:prepend>
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      </template>
      <v-spacer></v-spacer>
      <img height="100px" :src="require('@/assets/logoWebWide.png')" alt="">
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-main>

      <router-view />

      <v-footer rounded="xl" class="my-10 text-center d-flex flex-column" color="#D0E2FF">
        <div style="width:100%">
          <v-row class="mt-10">
            <v-col cols="12" md="3">
              <v-btn color="#2955A7" block size="large" variant="text">
                <v-icon icon="mdi-email"></v-icon>
                <span>info@duc.edu.iq</span>
              </v-btn>
            </v-col>
            <v-col cols="12" md="3">
              <v-btn color="#2955A7" block size="large" variant="text">
                <v-icon icon="mdi-phone"></v-icon>
                <span>6006</span>
              </v-btn>
            </v-col>
            <v-col cols="12" md="6">
              <v-btn color="#2955A7" block size="large" variant="text">
                <v-icon icon="mdi-map"></v-icon>
                <span>بغداد, الدورة , قرب جسر الطابقين</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-divider class="mt-10 mb-10"></v-divider>
          <b>Dijlah University College &copy; {{ new Date().getFullYear() }}</b>
          <br>
          <br>
        </div>
      </v-footer>
    </v-main>

  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    menu: [],
    drawer: true,
  }),
  created() {
    if (this.$vuetify.display.mobile) {
      this.drawer = false
    }
    this.fetch();
  },
  methods: {
    async fetch() {
      this.menu = (await this.axios.get("items/menu")).data.data;
    },
    menuClick(nav, link) {
      if (nav == null) {
        window.location.href = this.checkMenuLink(link);
      } else {
        return false;
      }
    },
    checkMenuLink(link) {
      if (link.includes('http')) {
        return link;
      } else {
        return '/page/' + link;
      }
    }
  }
}
</script>


<style src="./style.css"></style>