import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import axios from "axios";
import VueAxios from "vue-axios";
import { createHead } from "@unhead/vue";

loadFonts();

axios.defaults.baseURL = "https://dashboard.duc.edu.iq/";

const app = createApp(App);
const head = createHead();

app.use(router);
app.use(vuetify);
app.use(store);
app.use(head);
app.use(VueAxios, axios);
app.mount("#app");
